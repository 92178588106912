import React, { Component, Fragment } from "react"
import ScrollToTop from "react-scroll-up"
import Particles from "react-particles-js"
import { FiChevronUp } from "react-icons/fi"
import ServiceList from "../elements/service/ServiceList"
import FooterTwo from "../component/footer/FooterTwo"
import Contact from "../elements/contact/ContactTwo"

import SEO from "../components/seo"
import Header from "../component/header/HeaderFour"

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Netzwerk Digitaler Unternehmen",
    description: "Die EdelNetzwerk Beteiligungs GmbH investiert in gut positionierte Unternehmen mit Entwicklungspotenzial",
    buttonText: "Unser Portfolio",
    buttonLink: "#portfolio"
  }
]

class HomeParticles extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() {
  }

  render() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", function() {
        var value = window.scrollY
        if (value > 100) {
          document.querySelector(".header--fixed").classList.add("sticky")
        } else {
          document.querySelector(".header--fixed").classList.remove("sticky")
        }
      })

      const elements = document.querySelectorAll(".has-droupdown > a")
      for (let i in elements) {
        if (elements.hasOwnProperty(i)) {
          elements[i].onclick = function() {
            this.parentElement.querySelector(".submenu").classList.toggle("active")
            this.classList.toggle("open")
          }
        }
      }
    }

    return (
      <Fragment>
        <SEO title="Netzwerk Digitaler Unternehmen" lang="DE" />

        {/* Start Header Area  */}
        <Header headerPosition="header--transparent" color="color-white" logo="logo-light"/>
        {/* End Header Area  */}

        {/* Start Slider Area   */}

        <div className="slider-activation slider-creative-agency with-particles" id="home">
          <div className="frame-layout__particles">
            <Particles
              params={{
                "particles": {
                  "number": {
                    "value": 50
                  },
                  "size": {
                    "value": 4
                  }
                },
                "interactivity": {
                  "events": {
                    "onhover": {
                      "enable": true,
                      "mode": "repulse"
                    }
                  }
                }
              }}
            />
          </div>
          <div className="bg_image bg_image--27">
            {SlideList.map((value, index) => (
              <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                   key={index}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? <h1 className="title">{value.title}</h1> : ""}
                        {value.description ? <p className="description">{value.description}</p> : ""}
                        {value.buttonText ?
                          <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color"
                                                        href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Portfolio Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="section-title text-left mb--30">
                  <h2>Aktuelle und Ehemalige Beteiligungen</h2>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"/>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="Über Uns"/>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">Über Uns</h2>
                      <p className="description">EdelNetzwerk ist Konzeptgeber im Bereich Digitalisierung. Gemeinsam mit
                        Experten unterschiedlicher Fachrichtungen baut sie Unternehmen auf. Wir begleiten unsere
                        Beteiligungen aktiv auf dem Weg zu einer Realisierung ihres jeweiligen Wertpotentials.</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="imprint">
          <Contact/>
        </div>
        {/* End Contact Us */}

        {/* Start Footer Style  */}
        <FooterTwo/>
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

      </Fragment>
    )
  }
}

export default HomeParticles
