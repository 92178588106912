import React, { Component } from "react"
import { FiLayers } from "react-icons/fi"

class VehiclLogoComponent extends React.Component {
  render() {
    return (
      <div
           className={'vehicl-logo no-hover'}
      />
    );
  }
}

const ServiceList = [
  {
    icon: <img src="/assets/images/portfolio/automatenland.png" height="50" alt="Automatenland Logo"/>,
    title: "Automatenland GmbH",
    description: "Digitaler Handel, Prozesse und Lösungen für die Automatenbranche.",
    url: "https://automatenland-neuss.de"
  },
  {
    icon: <VehiclLogoComponent/>,
    title: "VEHICL GmbH",
    description: "Digitalisierte Prozesse und Lösungen für die Automobilindustrie.",
    url: "https://vehicl.com"
  },
  {
    icon: <img src="/assets/images/portfolio/gainandglory2.png" height="50" alt="Gain & Glory Logo"/>,
    title: "Gain & Glory GmbH",
    description: "Produktion und Vertrieb von Sportartikeln und Nahrungsergänzungsmitteln."
  },
  {
    icon: <img src="/assets/images/portfolio/edel.png" height="50" alt="EDEL Marketing Logo"/>,
    title: "EDEL Marketing- & Digitalgesellschaft mbH",
    description: "Marketing und Digitale Lösungen aus einer Hand.",
    url: 'https://edel.digital'
  },
  {
    icon: <img src="/assets/images/portfolio/maskenversand.png" height="50" alt="Maskenversand Logo"/>,
    title: "Maskenversand",
    description: "Versandhandel für Produkte der Arbeitssicherheit.",
    url: "https://maskenversand.online"
  },
  {
    icon: <img src="/assets/images/portfolio/repairmondo.png" height="50" alt="Repairmondo Logo"/>,
    title: "Repairmondo",
    description: "Online-Werkstatt für Smartphones und Spielekonsolen.",
    url: "https://repairmondo.de"
  }
]


class ServiceThree extends Component {
  render() {
    const { column } = this.props
    const ServiceContent = ServiceList.slice(0, this.props.item)

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={val.url} target="_blank">
                <div className="service service__style--2">
                  <div className="icon">
                    {val.icon}
                  </div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default ServiceThree
