import React, { Component } from "react";

class ContactTwo extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Impressum.</h2>
                                <p className="description">
                                    EdelNetzwerk Beteiligungs GmbH<br />
                                    Büdericher Str. 13<br />
                                    41460 Neuss<br />
                                    Deutschland<br />
                                    <br />
                                    Tel.: 0211 - 9763 5549<br />
                                    E-Mail: info@edelnetzwerk.com<br />
                                    <br />
                                    Registergericht: Amtsgericht Düsseldorf<br />
                                    Registernummer: HRB 86687<br />
                                    <br />
                                    Geschäftsführer: Moritz Haendel<br />
                                    <br />
                                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE325058799<br />
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.jpg" alt="Impressum"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;
